import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CallToAction from "../components/call-to-action"

const ThanksPage = () => {
  return (
    <Layout>
      <SEO
        description="Thanks for submitting your request to Slice"
        path="/thanks/"
        title="Thanks"
      />

      <div className="container py-72">
        <h1 className="h1 pb-8">Thanks!</h1>
        <p className="p1">
          Keep an eye out for an email from us.
          <br />
          We’re excited you are part of the movement to re-imagine insurance.
          <br />
          <br />
          <CallToAction label="Back to Slice Home" to="/" />
        </p>
      </div>
    </Layout>
  )
}

export default ThanksPage
